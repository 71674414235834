$loader-style: double;
$loader-width: 0.5em;
$loader-color: white;
$loader-size: 100px;
$loader-speed: 0.9s;

.loader {
  margin: 0 auto;
  width: 1em;
  height: 1em;
  font-size: $loader-size;
  position: relative;
  @media (max-width: $loader-size * 1.5), (max-height: $loader-size * 1.5) {
    font-size: $loader-size/2;
  }
  &:before, &:after {
    content: "";
    top: 0;
    display: block;
    width: 1em;
    height: 1em;
    position: absolute;
    border-width: $loader-width;
    border-style: $loader-style;
    border-color: transparent;
    box-sizing: border-box;
    border-radius: 1em;
    animation: spin $loader-speed infinite;
  }
  &:after {
    left: 0;
    border-left-color: #4BD095;
  }
  &:before {
    right: 0;
    border-right-color: #277776;
    animation-delay: $loader-speed/-4;
  }
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
}

.notFound {
  font-family: 'Roboto Light', sans-serif;
  font-size: 20px;
}