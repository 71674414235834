.alarm {
  position: absolute;
  top: 70px;
  left: 25%;
  align-items: end;
  background-color: white;
  border-radius: 10px;
  border: 3px solid #0F3B61;
  box-shadow: -10px 0px 25px 0px #00000012;
  transition: all 0.3s ease 0s;

  font-family: 'Roboto Light', sans-serif;
  padding: 20px;
  p {
    text-align: center;
  }

  span {
    text-decoration: underline;
  }
}

.link {
  color: black;
}