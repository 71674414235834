
.content__guide {
  background-color: #ffffff;
  border: 1px solid white;
}

.guide__flex {
  margin-top: 50px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  justify-content: space-between;
}

.guide__item {
  background-color: #F0F1F3;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 400;
  font-family: 'Roboto Light', sans-serif;
  flex: 0 1 32%;
}

.guide__item img{
  background: linear-gradient(180deg, #0F3B61 0%, #4BD095 100%);
  padding: 12px;
  border-radius: 10px;
  margin: 30px 0 26px 20px;
}

.guide__text {
  margin: 0 0 30px 20px;
}

.guide__item span {
  color: #4BD095;
}

.guide__button {
  font-family: 'Roboto Light', sans-serif;
  display: block;
  background: linear-gradient(268.41deg, #0F3B61 2.67%, #4BD095 98.49%);
  padding-top: 30px ;
  text-decoration: none;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  width: 33%;
  height: 83px;
  border-radius: 10px;
  margin: 0 auto 80px;
}