.header {
  height: 100px;
  flex-shrink: 0;
  padding-top: 20px;
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 555;
  width: 100% ;
  transition: all 0.3s ease 0s;
}

.navSticky {
  transition: all 0.3s ease 0s;
  top: 0;
}

.navSticky.active {
  top: 100px !important;
}

.header.hide {
  top: -100px;
}

.header__row {

  height: 53px;
  display: flex;
  align-items: center;
}

.header__burger {
  flex-shrink: 0;
  flex-basis: 60px;
  position: relative;
  height: 40px;
}

.header__burger:before,
.header__burger:after {
  content: '';
  background-color: #000;
  position: absolute;
  width: 100%;
  height: 3px;
  transition: all 0.3s ease 0s;
}

.header__burger:before {
  top: 5px;
}

.header__burger span {
  position: absolute;
  background-color: #000000;
  width: 100%;
  height: 3px;
  top: 18px;
  transition: all 0.3s ease 0s;
}

.header__burger:after {
  bottom: 5px;

}

.header__search {
  margin-left: 162px;
  margin-right: 30px;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.search_input {
  font-family: 'Roboto-light', sans-serif;
  font-size: 20px;
  border-radius: 10px;
  flex-grow: 1;
  height: 100%;
  padding-left: 30px;
  border: 2px solid #0F3B61;
  position: relative;
  left: 20px;
  z-index: 0;
}

.search_button {
  z-index: 1;
  height: 100%;
  flex-basis: 53px;
  border-radius: 10px;
  background: linear-gradient(#4BD095, #0F3B61);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.search__icon {
  max-width: 100%;
}

.header__buttons {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  font-family: 'Roboto-light', sans-serif;
  font-size: 20px;
  font-weight: 300;
}

.header__register {
  margin-right: 30px;
  background: linear-gradient(#4BD095, #0F3B61);
  padding: 15px 40px 15px 40px;
  border-radius: 10px;
  text-decoration: none;
  color: #fff;
}

.header__auth {
  background: linear-gradient(#4BD095, #0F3B61);
  padding: 15px 40px 15px 40px;
  border-radius: 10px;
  text-decoration: none;
  color: #fff;
}

.header__authed {
  height: 100%;
  flex-basis: 370px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.authed__profile {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.authed__info {
  display: flex;

  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Roboto Light', sans-serif;
}

.authed__image {
  margin-right: 15px;
}

.authed__image img {
  max-width: 100%;
}

.authed__name {
  margin-bottom: 6px;
}

.authed__exit {
  cursor: pointer;
}

.authed__mail {
  color: #60e7ae;
}


.header__burger.active:before {
  transform: rotate(45deg);
  top: 17px;
}

.header__burger.active:after {
  transform: rotate(-45deg);
  bottom: 20px;
}


.header__burger.active span {
  transform: scale(0);
}

.header__profile {
  display: none;
}


@media (max-width: 1199px) {
  .header__authed {
    display: none;
  }

  .header__buttons {
    display: none;
  }

  .header__profile {
    height: 100%;
    flex-basis: 53px;
    border-radius: 10px;
    background: linear-gradient(#4BD095, #0F3B61);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .profile__icon {
    max-width: 100%;
  }
}

@media (max-width: 959px) {
  .search_input {
    padding-left: 20px;
    padding-top: 5px;
    font-size: 16px;
  }

  ::placeholder {
    font-size: 16px;
  }

  .header__search {
    margin-left: 90px;
    margin-right: 18px;
  }

  .header__burger {
    flex-basis: 50px;
  }

  .menu__list li {;
    font-size: 14px;
  }
}

@media (max-width: 639px) {


  .header__profile {
    background: #0F3B61;
  }

  .search_button {
    background: #0F3B61;
  }

  .header__row {
    margin-top: 12px;
    height: 33px;
  }

  .header__profile {
    flex-basis: 30px;
    border-radius: 5px;
  }

  .profile__icon {
    max-width: 50%;
  }

  .search_input {
    border-radius: 5px;
    font-size: 10px;
  }

  .search__icon {
    max-width: 50%;
  }

  .search_button {
    border-radius: 5px;
    flex-basis: 30px;
  }

  .search_input::placeholder {
    font-size: 10px;
  }

  .search_input {
    padding-bottom: 6px;
  }

  .header__burger {
    flex-shrink: 0;
    flex-basis: 30px;
    position: relative;
    height: 30px;
  }

  .header__burger span {
    position: absolute;
    background-color: #000000;
    width: 100%;
    height: 3px;
    top: 13px;
    transition: all 0.3s ease 0s;
  }

  .header__burger.active:before {
    top: 10px;
  }

  .header__burger.active:after {
    bottom: 17px;
  }

  .header {
    height: 55px;
    padding-top: 0;
  }
  .header.hide {
    top: -55px;
  }




}

@media (max-width: 479px) {

  .search_input {
    padding-left: 10px !important;
  }

  .header {
    height: 55px;
  }

  .header__search {
    margin-left: 40px;
  }

  .header__search {
    margin-left: 40px;
    margin-right: 0;
  }

  .header__profile {
    display: none;
  }

}