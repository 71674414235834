.notFound {
  margin-top: 100px;
  h1 {
    font-size: 44px;
    text-align: center;
  }

  p {
    font-size: 24px;
    text-align: center;
  }
}