.panels__projects {
  margin-left: 80px;
}

.projects__top {
  display: flex;
  justify-content: start;
  position: relative;
  align-items: center;
  margin-bottom: 30px;
}

.projects__title {
  font-size: 24px;
  font-weight: 700;
  margin-right: 30px;
}

.projects__counter {
  font-size: 16px;
  margin-right: auto;
  color: #A5A5A5;
}

.projects__clear {
  border: 3px solid #000000;
  border-radius: 8px;
  padding: 5px 12px;
  font-size: 36px;
  font-weight: 400;
  text-decoration: none;
  color: #000000;
  transition: all 0.2s ease 0s;

  &.active {
    transform: rotate(45deg);
  }
}

.projects__clear-icon {
  display: none;
}

.projects__folders {
  background-color: #DFDFDF;
  border-radius: 10px;
  position: relative;
  padding: 22px 0 26px 28px;
  margin-bottom: 25px;
  cursor: pointer;
}

.projects__name {
  width: 90%;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
}

.projects__quantity {
  font-size: 16px;
  width: 90%;
}

.projects__expand {
  position: absolute;
  left: 730px;
  top: 45px;
}

.projects__expand:before,
.projects__expand:after {
  content: '';
  background-color: #000;
  position: absolute;
  width: 15px;
  height: 3px;
  transition: all 0.2s ease 0s;
}

.projects__expand:before {
  transform: rotate(45deg);
  left: -3px
}

.projects__expand:after {
  transform: rotate(-45deg);
  left: 5px;
}

.projects__expand {
  &.active {
    &:before {
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
    }

  }
}

.projects__content {
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;
  padding: 22px 65px 26px 28px;
  margin-bottom: 25px;
}

.projects__doc {
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 10px;
}

.projects__delete {
  position: absolute;
  font-size: 20px;
  left: 95%;
  top: 15px;
  color: black;
  cursor: pointer;
}


@media (max-width: 1199px) {
  .panels__projects {
    margin-left: 40px;
  }

  .projects__top {
    align-items: start;
    margin-bottom: 30px;
  }

  .projects__counter {
    position: relative;
    right: 205px;
    top: 38px
  }

  .projects__expand {
    left: 480px;
  }

  .projects__clear {
    padding: 7px 15px;

  }

  .projects__content {
    padding: 22px 70px 26px 28px;
  }

  .projects__delete {
    left: 94%;
  }

}

@media (max-width: 959px) {
  .panels__projects {
    margin-left: 0;
  }

  .projects__top {
    align-items: center;
    margin-bottom: 30px;
  }

  .projects__counter {
    position: static;
    right: 0;
    top: 0;
  }

  .projects__title {
    font-size: 30px;
  }

  .projects__counter {
    font-size: 18px;
  }

  .projects__clear {
    font-size: 26px;
    padding: 4px 10px;
  }

  .projects__expand {
    left: 540px;
    top: 20px
  }

  .projects__delete {
    left: 542px;
    top: 20px;
  }

}

@media (max-width: 639px) {

  .projects__title {
    font-size: 24px;
  }

  .projects__counter {
    font-size: 16px;
  }

  .projects__name {
    font-size: 18px;
  }

  .projects__expand {
    left: 390px;
  }

  .projects__clear {
    font-size: 22px;
    padding: 3px 9px;
  }

  .projects__delete {
    left: 392px;
  }

  .projects__content {
    padding: 22px 45px 26px 28px;
  }


}

@media (max-width: 479px) {

  .projects__folders {
    padding: 14px 0 17px 19px;
    margin-bottom: 15px;
  }

  .projects__expand {
    left: 247px;
  }

  .projects__title {
    font-size: 16px;
  }

  .projects__counter {
    font-size: 10px;
  }

  .projects__clear {
    font-size: 16px;
    padding: 2px 8px;
    border: 2px solid #000000;
  }

  .projects__name, {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .projects__quantity {
    font-size: 14px;
  }

  .projects__doc {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  .projects__delete {
    left: 249px;
  }

  .projects__content {
    padding: 14px 40px 17px 19px;
    margin-bottom: 15px;

  }


}