.profile__sidebar {
  margin-top: 10px;
  flex-basis: 330px;
  flex-shrink: 0;
}

.sidebar__item {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.sidebar_text {
  cursor: pointer;
  &.active {
    color: #4BD095;
  }

  margin-left: 30px;
  font-weight: 400;
  text-decoration: none;
  color: black;
  font-size: 20px;
}

.sidebar__image {
  cursor: pointer;
}


@media (max-width: 1199px) {
}

@media (max-width: 959px) {
  .profile__sidebar {
    align-self: center;
    width: 90%;
    margin-top: 0;
    flex-basis: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    row-gap: 30px;
  }

  .sidebar__item {
    flex-grow: 0;
    flex-shrink: 0;
    row-gap: 15px;
    flex-basis: 150px;
    margin: 0 30px 0 0;
    flex-direction: column;
    align-items: center;

    &:nth-child(3n+3) {
      margin: 0;
    }
  }

  .sidebar_text {

    text-align: center;
    margin-left: 0;
    font-size: 20px;
    &.active {
      color: black;
    }
    span {
      display: none;
    }
  }

  .sidebar__image {
    border-radius: 10px;
    background-color: #ffffff;
    &.active {
      background: linear-gradient(90.34deg, #0F3B61 30.48%, #4BD095 98.18%);
    }
    aspect-ratio: 1 / 1;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      &.active {
        filter: brightness(0) invert(1);
        -webkit-filter: brightness(0) invert(1);
      }
      width: 50%;
    }
  }

}

@media (max-width: 639px) {
  // ---------------

  .profile__sidebar {
    width: 95%;
    row-gap: 26px;
  }

  .sidebar__item {

    row-gap: 15px;
    flex-basis: 120px;
    margin: 0 13px 0 0;
    &:nth-child(1) {
      flex-basis: 134px;
    }
    &:nth-child(4) {
      flex-basis: 134px;
    }

  }

  .sidebar_text {
    font-size: 18px;
  }

  .sidebar__image {
    width: 120px;
  }

  // ---------------

}

@media (max-width: 479px) {
  .profile__sidebar {
    width: 100%;
    row-gap: 10px;
  }

  .sidebar__item {

    row-gap: 5px;
    flex-basis: 86px;
    margin: 0 6px 0 0;
    &:nth-child(1) {
      flex-basis: 86px;
    }
    &:nth-child(4) {
      flex-basis: 86px;
    }

  }

  .sidebar_text {
    font-size: 11px;
  }

  .sidebar__image {
    width: 86px;
  }
}