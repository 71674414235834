.box {
  background-color: white;
  border-radius: 10px;
  max-width: 600px;
  margin: 80px auto 80px;
  padding: 30px;
  font-family: 'Roboto Light', sans-serif !important;

  h1 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 32px;
  }

  p {
    font-size: 20px;
    margin-bottom: 10px;
  }
}

.warning {
  font-size: 16px;
  color: red;
  padding-bottom: 25px;
  max-width: 420px;
}

.TextField {

  max-width: 420px;
  width: 100%;
  height: 50px;
  border: 2px solid #0F3B61;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 14px 20px 14px 30px;
  font-size: 20px;
  font-weight: 400;
}

.button {
  background: linear-gradient(90.34deg, #0F3B61 30.48%, #4BD095 98.18%);
  border-radius: 10px;
  padding: 20px 90px;
  color: white;

  font-size: 20px;
  font-weight: 700;
}