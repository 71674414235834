.text {
  font-family: 'Roboto Light', sans-serif;
  font-size: 20px;
  text-align: center;
  padding-bottom: 20px;
}

.buttons {


  display: flex;
  justify-content: space-around;
  flex-grow: 1;
  font-family: 'Roboto Light', sans-serif;
  font-weight: 700;

}

.yes {
  font-weight: 700;
  font-family: 'Roboto Light', sans-serif;
  border-radius: 10px;
  color: white;
  background-color: green;
  padding: 10px 37px;
  margin-right: 40px;

}

.no {
  color: white;
  font-weight: 700;
  font-family: 'Roboto Light', sans-serif;
  padding: 10px 37px;
  border-radius: 10px;
  background-color: red;


}