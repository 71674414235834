@import "components/about";
@import "components/advantages";

@import "components/burgermenu";
@import "components/functions";
@import "components/guide";
@import "components/welcome";

.title {
  margin-top: 130px;
  font-family: 'Roboto Light', sans-serif;
  font-size: 55px;
  font-weight: 700;
  text-align: center;
  position: relative;
}

.content__about {
  background-color: #fff;
  border: 1px solid white;
}

.subtitle {
  margin-top: 20px;
  font-family: 'Roboto Light', sans-serif;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
}


@media (max-width: 1199px) {

  .guide__text {
    margin-right: 26px;
  }

  .guide__flex {
    flex-direction: column;
    align-items: center;
    row-gap: 25px;
  }

  .guide__item:nth-child(1) {
    width: 488px;
  }

  .guide__item:nth-child(2) {
    width: 540px;
  }

  .guide__item:nth-child(3) {
    width: 660px;
  }

  .guide__button {
    height: 73px;
    width: 356px;
    padding-top: 25px;
  }

  .advantages__item {
    font-size: 18px;
    padding: 20px 20px;
  }

  .advantages__flex {
    margin-bottom: 80px;
  }


  .advantages__item:nth-child(1) {
    order: 1;
  }

  .advantages__item:nth-child(4) {
    order: 2;
  }

  .advantages__item:nth-child(7) {
    order: 3;
  }

  .advantages__item:nth-child(5) {
    order: 4;
  }

  .advantages__item:nth-child(3) {
    order: 5;
  }

  .advantages__item:nth-child(6) {
    order: 6;
  }

  .advantages__item:nth-child(8) {
    order: 7;
  }

  .advantages__item:nth-child(9) {
    order: 8;
  }

  .advantages__item:nth-child(2) {
    order: 9;
    width: 55%;
    text-align: start;
  }

  .advantages__item:nth-child(10) {
    width: 40%;
    order: 10;
    margin-right: 0;
  }

  .advantages__item:nth-child(11) {
    order: 11;
    margin-left: 0;
  }



  .title {
    font-size: 45px;
  }

  .functions__item:nth-child(7) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .functions__grid {
    justify-content: space-evenly;
    row-gap: 30px;
  }

  .welcome__grid {
    grid-template-columns: 60% 40%;
  }

  .welcome__title {
    font-size: 45px;
    line-height: 45px;
  }

  .welcome__subtitle {

    font-size: 20px;
    width: 90%;
    margin-bottom: 30px;
  }

  .welcome__subtitle br {
    display: none;
  }

  .container {
    max-width: 900px;
  }
}

@media (max-width: 959px) {

  .guide__text {
    margin: 0 18px 20px 20px;
  }

  .guide__flex {
    row-gap: 20px;
  }

  .guide__item:nth-child(1) {
    width: 485px;
  }

  .guide__item:nth-child(2) {
    width: 400px;
  }

  .guide__item:nth-child(3) {
    width: 385px;
  }

  .guide__item img {
    padding: 8px;
    width: 40px;
    margin: 25px 0 13px 20px;
  }

  .guide__button {
    height: 53px;
    width: 316px;
    padding-top: 15px;
  }

  .advantages__dino {
    max-width: 90%;
  }

  .advantages__flex {
    row-gap: 20px;
  }

  .advantages__item {
    padding: 15px 40px;
  }

  .advantages__item:nth-child(1) {
    order: 1;
  }

  .advantages__item:nth-child(3) {
    order: 2;

  }

  .advantages__item:nth-child(2) {
    width: auto;
    text-align: center;
    order: 3;
  }

  .advantages__item:nth-child(10) {
    width: auto;
    order: 4;
  }

  .advantages__item:nth-child(4) {
    width: 78%;
    order: 5;
  }

  .advantages__item:nth-child(5) {
    order: 6;
  }

  .advantages__item:nth-child(8) {
    order: 7;
    width: 75%;
  }

  .advantages__item:nth-child(6) {
    order: 8;
    width: 60%;
    padding: 15px 14px;
  }

  .advantages__item:nth-child(9) {
    order: 9;
    width: 70%;
  }

  .advantages__item:nth-child(11) {
    order: 10;
  }

  .advantages__item:nth-child(7) {
    order: 11;
  }

  .advantages__item:nth-child(3) span {
    display: inline-block;
  }

  .advantages__item:nth-child(1) span {
    display: none;
  }

  .backContainer {
    display: none;
  }

  .title {
    font-size: 40px;
    margin-top: 80px;
  }

  .about__grid {
    grid-template-columns: minmax(280px, 500px);
    margin-bottom: 100px;
  }

  .about__info {
    font-size: 16px;
  }

  .about__text:nth-child(1) {
    width: 90%;
    margin: 17px 0 0 0;
    font-size: 18px;
  }

  .about__text:nth-child(2) {
    margin: 9px 0 9px 0;
    width: 80%;
  }

  .about__text:nth-child(3) {
    margin: 0 0 21px 0;
    width: 80%;
  }

  .about__info span {
    font-weight: 300;
  }

  .about__info br {
    display: none;
  }

  .about_button {
    padding: 15px 60px;
  }

  .functions__item:nth-child(7) {
    grid-column-start: auto;
    grid-column-end: auto;
  }

  .functions__grid {
    grid-template-columns: repeat(auto-fit, minmax(280px, 440px));
    row-gap: 20px;
  }

  .functions__image {
    width: 40px;
    margin-bottom: 12px;
  }

  .functions__title {
    margin-bottom: 11px;
  }

  .functions__subtitle {
    margin-bottom: 23px;
    width: 90%;
  }

  .welcome__grid {
    grid-template-columns: 1fr;
  }

  .welcome__info {
    text-align: center;
    justify-items: center;
    grid-template: auto auto auto / 1fr;
  }

  .welcome__title {
    font-size: 40px;
    line-height: 40px;
  }

  .welcome__subtitle {
    width: 80%;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .welcome__subtitle span{
    font-weight: 400;
  }

  .welcome_button {
    font-size: 20px;
    padding: 15px 60px;

  }

  .welcome_button {
    justify-self: center;
  }

  .welcome__dino {
    display: none;
  }



  .container {
    max-width: 580px;
  }


}

@media (max-width: 639px) {

  .guide__text {
    margin: 0 0 20px 20px;
  }

  .guide__flex {
    row-gap: 20px;
  }

  .guide__item:nth-child(n) {
    width: 330px;
  }

  .guide__item {
    font-size: 16px;
  }

  .guide__button {
    height: 53px;
    width: 316px;
    padding-top: 15px;
    font-size: 18px;
  }

  .advantages__flex {
    row-gap: 15px;
  }

  .advantages__item {
    font-size: 16px;
  }

  .advantages__item:nth-child(n) {
    width: auto;
  }

  .advantages__item:nth-child(1) span {
    display: none;
  }

  .advantages__item:nth-child(3) {
    width: fit-content;
  }

  .advantages__item:nth-child(2) {
    text-align: center;
    width: 90%;
  }

  .advantages__item:nth-child(10) {
    padding: 15px 30px;
  }

  .advantages__item:nth-child(4) {
    width: 95%;
  }

  .advantages__item:nth-child(5) {
    padding: 15px 30px;
  }

  .advantages__item:nth-child(8) {
    width: 92%;
  }

  .advantages__item:nth-child(6) {
    width: 75%;
  }

  .advantages__item:nth-child(9) {
    width: 86%;
  }

  .title {
    font-size: 30px;
  }

  .about__info {
    margin-top: 46px;
  }

  .functions__item:nth-child(7) .functions__subtitle {
    width: 90%;
  }

  .functions__grid {
    grid-template-columns: repeat(auto-fit, minmax(280px, 320px));
    row-gap: 25px;
  }

  .functions__image {
    margin-bottom: 11px;
  }

  .functions__title {
    margin-bottom: 3px;
    font-size: 18px;
  }

  .functions__subtitle {
    margin-bottom: 21px;
    width: 85%;
    font-size: 18px;
  }

  .welcome__info {
    justify-items: normal;
    align-items: normal;;
    text-align: start;
  }

  .welcome__title {
    width: 80%;
    justify-self: center;
    font-size: 30px;
    line-height: 35px;
  }

  .welcome__subtitle {
    text-align: center;
    width: 100%;
    font-size: 18px;
    margin-bottom: 15px;
  }

  .welcome_button {
    font-size: 18px;
  }




  .container {
    max-width: 430px;
  }

  .subtitle {
    font-size: 18px;
  }
}

@media (max-width: 479px) {

  .guide__text {
    margin: 0 0 20px 20px;
  }

  .guide__flex {
    row-gap: 20px;
    margin-top: 30px;
  }

  .guide__item:nth-child(n) {
    width: 280px;
  }

  .guide__item {
    font-size: 14px;
  }


  .guide__button {
    height: 50px;
    width: 280px;
    padding-top: 15px;
    font-size: 16px;
  }

  .advantages__dino {
    max-width: 100%;
  }

  .advantages__flex {
    margin-bottom: 40px;
  }

  .container {
    max-width: 280px;
  }

  .advantages .container {
    max-width: 300px;
  }

  .advantages__flex {
    row-gap: 10px;
  }

  .advantages__item {
    font-size: 14px;
  }

  .advantages__item:nth-child(n) {
    width: auto;
    padding: 15px 20px;
  }

  .advantages__item:nth-child(1) {
    width: 75%;
  }

  .advantages__item:nth-child(2) {
    width: 90%;
  }

  .advantages__item:nth-child(10) {
    width: 75%;
  }

  .advantages__item:nth-child(5) {
    width: 75%;
  }

  .advantages__item:nth-child(6) {
    width: 90%;
  }

  .advantages__item:nth-child(11) {
    padding: 15px 12px;
  }

  .about__grid {
    margin-bottom: 70px;
  }

  .about__info {
    margin-top: 30px;
  }

  .content__about .title,
  .content__guide .title {
    text-align: start;
  }

  .content__about .subtitle,
  .content__guide .subtitle {
    text-align: start;
  }

  .subtitle {
    font-size: 14px;
  }

  .title {
    font-size: 24px;
    margin-top: 60px;
  }

  .about__info {
    font-size: 14px;
  }

  .about__text:nth-child(1) {
    width: 85%;
    margin: 17px 0 0 0;
    font-size: 14px;
  }

  .about__text:nth-child(2),
  .about__text:nth-child(3) {
    align-self: start;
    margin-left: 40px;
  }

  .about_button {
    padding: 15px 88px;
    font-size: 16px;
  }

  .functions__grid {
    grid-template-columns: repeat(auto-fit, minmax(280px, 280px));
    row-gap: 25px;
  }

  .functions__image {
    margin-bottom: 11px;
  }

  .functions__title {
    margin-bottom: 3px;
    font-size: 16px;
  }

  .functions__subtitle {
    margin-bottom: 17px;
    width: 85%;
    font-size: 14px;
  }


  .welcome__title {
    width: 100%;
    font-size: 24px;
    line-height: 35px;
  }

  .welcome__subtitle {
    text-align: start;
    width: 100%;
    font-size: 14px;
    margin-bottom: 27px;
  }

  .welcome_button {
    justify-self: start;
    font-size: 16px;
  }


}