@import "common/header";
@import "common/footer";
@import "libs/nullstyle";
@import "main/app";
@import "find/app";
@import "profile/app";
@import "animations/app";
@import "bookmarkMenu/app";

#root {
  height: 100%;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;

}

.content {
  margin-top: 100px;
  position: relative;
  flex-grow: 1;
  background-color: #F0F1F3;
}

.container {
  max-width: 1180px;
  margin: 0 auto;
}




@media (max-width: 1199px) {
  .container {
    max-width: 900px;
  }
}

@media (max-width: 959px) {
  .container {
    max-width: 580px;
  }

}

@media (max-width: 639px) {
  .container {
    max-width: 430px;
  }

  .content {
    margin-top: 55px;
  }


}

@media (max-width: 479px) {
  .container {
    max-width: 280px;
  }

}


