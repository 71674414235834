.form {
  background-color: white;
  margin: 80px auto 80px;
  padding: 40px 60px 34px 60px;
  max-width: 600px;
  font-family: 'Roboto Light', sans-serif !important;
  border-radius: 10px;
}

.google {
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  column-gap: 15px;
  align-items: center;
  font-size: 20px;
  text-decoration: underline;
  text-underline-offset: 4px;
}

.warning {
  font-size: 16px;
  color: red;
  padding-bottom: 25px;
  max-width: 420px;
}

.serverWarning {
  font-size: 20px;
  color: red;

  padding-bottom: 20px;
}

.logo {
  padding: 0 0 60px 0;
  font-size: 32px;
  font-weight: 700;
}

.success {
  padding-bottom: 15px;
  font-size: 26px;
  color: #077541;
}

.title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 32px;
}

.label {
  font-size: 20px;
  padding-bottom: 10px;
}

.TextField {
  max-width: 420px;
  width: 100%;
  height: 50px;
  border: 2px solid #0F3B61;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 14px 20px 14px 30px;
  font-size: 20px;
  font-weight: 400;
}

.button {
  background: linear-gradient(90.34deg, #0F3B61 30.48%, #4BD095 98.18%);
  border-radius: 10px;
  padding: 20px 90px;
  color: white;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 700;
}

.text {
  padding-bottom: 20px;
  font-size: 20px;

  span {
    cursor: pointer;
    color: #4BD095;
  }
}

.link {
  cursor: pointer;
  color: #4BD095;
}

.agreement {
  line-height: 21px;
  padding-top: 50px;
  font-size: 16px;
  font-weight: 400;

  span {
    cursor: pointer;
    font-weight: 700;
    text-decoration: underline;
  }
}

.confirmText {
  line-height: 21px;
  padding: 20px 0 20px 0;
  font-size: 16px;
  font-weight: 400;

  span {
    cursor: pointer;
    font-weight: 700;
    text-decoration: underline;
  }
}

@media (max-width: 1199px) {

}

@media (max-width: 959px) {


}

@media (max-width: 639px) {

  .form {
    margin: 50px auto 50px;
  }

  .button {
    font-size: 18px;
    padding: 20px 45px;

  }

  .logo {

    padding: 40px 0 60px 0;
    font-size: 28px;

  }

  .title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 32px;
  }

  .TextField {

    font-size: 18px;

  }

  .google {
    font-size: 18px;

    img {
      width: 25px;
    }
  }


  .text {
    line-height: 20px;
    font-size: 18px;
  }

  .agreement {
    padding-top: 45px;
    font-size: 14px;

  }
}

@media (max-width: 479px) {
  .google {
    font-size: 14px;

    img {
      width: 20px;
    }
  }

  .TextField {
    font-size: 16px;
  }

  .form {
    padding: 0 20px 34px 20px;
    margin: 50px auto 50px;

  }

  .button {
    display: block;
    margin: 0 auto;
    font-size: 16px;
    margin-bottom: 30px;
    padding: 20px 30px;
    width: 100%;
  }

  .logo {
    padding: 40px 0 40px 0;
    font-size: 28px;
  }

  .title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 32px;
  }

  .text {
    font-size: 16px;
  }

  .agreement {
    padding-top: 30px;
    font-size: 12px;

  }

}