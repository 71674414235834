.confirmBlock {
  margin-top: 50px;
  background-color: white;
  width: 100%;
  padding: 50px 20px;

  p {
    text-align: center;
    font-size: 24px;
  }

  h1 {
    cursor: pointer;
    padding-top: 15px;
    text-align: center;
    font-size: 24px;
    text-decoration: underline;
  }

}

@media (max-width: 1199px) {

}

@media (max-width: 959px) {


}

@media (max-width: 639px) {
  .confirmBlock {
    padding: 30px 20px;


    p {

      font-size: 16px;
    }

    h1 {
      font-size: 16px;
    }

  }
}

@media (max-width: 479px) {
  .confirmBlock {



    padding: 10px;

    p {

      font-size: 10px;
    }

    h1 {

      font-size: 10px;
    }

  }

}